<template>
	<div class="row offers mt-2">
		<b-col lg="12">
			<div class="card">
				<b-row class="mt-4">
					<b-col cols="12">
						<router-link class="btn btn-sm btn-default" :to="{name: ReportInvoicePath.name}">
							<em class="fa fa-backward"></em> Manage Invoice
						</router-link>
					</b-col>
				</b-row>
				<b-row align-h="center">
					<b-col lg="6">
						<div class="p-2">
							<div class="mt-2 text-center">Search Invoice Transaction Code</div>
							<b-input-group class="mt-3">
								<b-form-input
									v-model="query_search"
									type="search"
									placeholder="Type to search"
									@change="setQuerySearch"
									trim
								></b-form-input>
								<b-input-group-append>
									<b-button type="submit" variant="primary" @click="asyncFind">Search</b-button>
									<b-button type="button" variant="dark" class="ml-2" @click="asyncClearFind">Clear</b-button>
									<b-spinner v-show="isLoadingSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-col>
				</b-row>
				<div class="card-body">
					<div class="row justify-content-end pb-1">
						<span>
							<button class="btn btn-sm btn-dark" @click="actionGetReportInvoiceSingle">
								<em class="fa fa-refresh"></em>
							</button>
						</span>
						<label for="" class="col-sm-auto">Count </label>
						<strong class="col-sm-auto">{{ totalRows }}</strong>
					</div>
					<b-table
						sticky-header="500px"
						:items="items"
						:fields="fields"
						:busy="isLoading"
						:isLoading="isLoading"
						show-empty
						responsive="xl"
						class="mt-2"
						hover
					>
						<template v-slot:table-busy>
							<div class="text-center text-dark my-2">
								<b-spinner
									class="align-middle"
									variant="dark"
									type="grow"
									label="Loading .."
								>
								</b-spinner>
								Loading ..
							</div>
						</template>
						<template #cell(No)="row">
							{{ (currentPage - 1) * perPage + (row.index + 1) }}
						</template>
						<template #cell(created)="row">
							{{ formatDate(row.item.created) }}
						</template>
						<template #cell(disbursement_period)="row">
							{{ formatPeriod(row.item.disbursement_period) }}
						</template>
						<template #cell(transaction_code)="row">
							{{ row.item.transaction_code }}
						</template>
						<template #cell(disbursement)="row">
							{{ previewCurrencyIdr(row.item.disbursement) }}
						</template>
						<template #cell(balance)="row">
							{{ previewCurrencyIdr(row.item.balance) }}
						</template>
						<template #cell(item_idax)="row">
							{{ row.item.item_idax }}
						</template>
						<template #cell(action_show)="row">
							<router-link class="btn btn-sm btn-default" :to="{name: ReportInvoiceSingleDetailPath.name, params: { id: invoice_id, detailId: row.item.id }}">
								<em class="fa fa-eye"></em>
							</router-link>
						</template>
					</b-table>
				</div>
				<b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1 mt-2 pt-4">
					<b-col lg="3">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							aria-controls="my-table"
						/>
					</b-col>
				</b-row>
			</div>
		</b-col>
	</div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { ReportInvoicePath, ReportInvoiceSingleDetailPath } from "../../router/report";
export default {
	name: "manage-report-invoice-single",
	data() {
		return {
			debounce: null,
			ReportInvoicePath,
			ReportInvoiceSingleDetailPath,
			invoice_id: null,
			currentPage: 1,
			perPage: 20,
			query_search: '',
			isLoadingSearch: false,
			fields: [
				{ key: "No", label: "No" },
				{
					key: "created",
					label: "Created",
				},
				{
					key: "disbursement_period",
					label: "Period",
				},
				{
					key: "transaction_code",
					label: "Transaction Code",
				},
				{
					key: "disbursement",
					label: "Disbursement",
				},
				{
					key: "balance",
					label: "Balance",
				},
				{
					key: "item_idax",
					label: "ID AX",
				},
				{ key: "action_show", label: "Show" },
			],
		};
	},
	computed: {
		...mapState({
			isLoading: (state) => state.report.isLoading,
			items: (state) => state.report.items,
			isError: (state) => state.report.isError,
			totalRows: (state) => state.report.totalRows,
		}),
	},
	created() {
		this.invoice_id = this.$route.params.id;
		this.actionGetReportInvoiceSingle();
	},
	watch: {
		currentPage: function() {
			this.actionGetReportInvoiceSingle();
		},
	},
	methods: {
		...mapActions("report", ["fetchReportInvoiceById", "searchReportInvoiceDetail"]),
		actionGetReportInvoiceSingle() {
			if (this.query_search == "") {
				let payload = {
					id: this.invoice_id,
					page: this.currentPage,
					limit: this.perPage,
				};
				this.fetchReportInvoiceById(payload);
			} else {
				this.asyncFind();
			}
		},
		setQuerySearch(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search = query;
			}, 1000);
		},
		formatDate(tgl) {
			if (tgl) {
				return moment(tgl).format("DD MMM YY hh:mm");
			}
			return "-";
		},
		formatPeriod(tgl) {
			if (tgl) {
				return moment(tgl).format("YYYY MMM");
			}
			return "-";
		},
		asyncFind() {
			this.isLoadingSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search;
				if (!query) {
					this.actionGetReportInvoiceSingle();
					this.isLoadingSearch = false;
					return;
				}
				this.searchReportInvoiceDetail({
					id: this.invoice_id,
					q: query,
					page: this.currentPage,
					limit: this.perPage,
				})
					.then((response) => {
						this.items = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
			}, 1000);
		},
		asyncClearFind() {
			this.query_search = "";
			this.actionGetReportInvoiceSingle();
			this.isLoadingSearch = false;
		},
		previewCurrencyIdr(value) {
			return new Intl.NumberFormat(
				'id-ID',
				{
					style: 'currency',
					currency: 'IDR'
				}
			).format(value ?? 0);
		},
	},
};
</script>
